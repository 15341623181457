import { Component, Ref, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import FileUploader from './FileUploader/FileUploader.vue'
import { GeofenceService } from '@/App/Services/Geofence.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { Geofence } from '@/store/interfaces/geofence.interfaces'

const sharedStore = namespace('Shared')
const geofenceStore = namespace('Geofence')

@Component({
  components: { FileUploader }
})
export default class FileUploadDialog extends Vue {
  @Ref() public readonly fileUploader!: { reset: () => void };

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @geofenceStore.Mutation
  private setGeofences: (geofences: Geofence[]) => void

  public file = '';
  public isOpen = false;
  public isLoading = false;

  public async upload(): Promise<void> {
    try {
      this.isLoading = true

      await GeofenceService.import(this.file)

      this.setNotification({ text: this.$t('geofencePage.geofenceList.fileUploadDialog.geofenceImportedSuccess.').toString() })

      this.fileUploader.reset()

      this.setGeofences(await GeofenceService.fetch())

      this.isOpen = false
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }
}
