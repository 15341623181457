import { render, staticRenderFns } from "./Geofence-List-Item.vue?vue&type=template&id=28323701&scoped=true&"
import script from "./GeofenceListItem.ts?vue&type=script&lang=ts&"
export * from "./GeofenceListItem.ts?vue&type=script&lang=ts&"
import style0 from "./GeofenceListItem.scss?vue&type=style&index=0&id=28323701&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28323701",
  null
  
)

export default component.exports