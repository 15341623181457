import { Component, Vue } from 'vue-property-decorator'
import { LMap, LTileLayer, LControlZoom, LPolygon, LCircle, LMarker, LTooltip } from 'vue2-leaflet'
import { namespace } from 'vuex-class'
import { LeafletMouseEvent, Util, LatLngLiteral, divIcon } from 'leaflet'
import GeofenceList from './GeofenceList/Geofence-List.vue'
import { StoreGeofence } from '@/App/Services/interfaces/geofence.interface'
import { Geofence } from '@/store/interfaces/geofence.interfaces'
import isArray = Util.isArray

const geofenceStore = namespace('Geofence')

@Component({
  components: { LMap, LTileLayer, LControlZoom, LPolygon, GeofenceList, LCircle, LMarker, LTooltip }
})
export default class LocationsPage extends Vue {
  @geofenceStore.State
  private canAddPoint: boolean

  @geofenceStore.State
  private geofences: Geofence[]

  @geofenceStore.State
  private geofenceForm: StoreGeofence

  @geofenceStore.Mutation
  private updateGeofenceForm: (geofence: Partial<StoreGeofence>) => void

  public $refs: {
    map: HTMLFormElement
  }

  public zoom = 2
  public center: [number, number] = [0, 0]
  public tripEmulationStatus = false
  public isDragEventActive = false
  public icon = divIcon({
    html: '<div></div>',
    className: 'polygon-point'
  })

  public mapReady(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.zoom = 18
      setTimeout(() => this.center = [position.coords.latitude, position.coords.longitude], 500)
    })
  }

  public centeringMap(position: [number, number] | [number, number][]): void {
    if (isArray(position[0])) {
      this.$refs.map.mapObject.fitBounds(position)
    } else {
      this.zoom = 18
      //@ts-ignore
      setTimeout(() => this.center = position, 500)
    }
  }

  public mapClicked({ latlng }: LeafletMouseEvent): void {
    if (this.isDragEventActive) {
      return
    }

    if (this.canAddPoint) {
      if (this.geofenceForm.type === 'circle') {
        this.updateGeofenceForm({ coordinates: [[latlng.lat, latlng.lng]] })
      } else {
        this.updateGeofenceForm({ coordinates: [...this.geofenceForm.coordinates, [latlng.lat, latlng.lng]] })
      }
    }
  }

  public polygonStateChanged(e: LatLngLiteral, idx: number): void {
    const coordinates = [...this.geofenceForm.coordinates]
    coordinates[idx] = [e.lat, e.lng]
    this.updateGeofenceForm({ coordinates })
    this.isDragEventActive = false
  }

  public polygonPointMouseUp(): void {
    setTimeout(() => this.isDragEventActive = false, 500)
  }

  get showNewCircleGeofence(): boolean {
    return this.canAddPoint && this.geofenceForm.type === 'circle' && !!this.geofenceForm.coordinates[0] && !!this.geofenceForm.radius
  }

  get circleActiveGeofences(): Geofence[] {
    return this.geofences.filter(g => g.type === 'circle' && g.isShowOnMap)
  }

  get polygonActiveGeofences(): Geofence[] {
    return this.geofences.filter(g => g.type === 'polygon' && g.isShowOnMap)
  }

  get showNewPolygonGeofence(): boolean {
    return this.canAddPoint && this.geofenceForm.type === 'polygon'
  }
}
