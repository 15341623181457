import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GeofenceService } from '@/App/Services/Geofence.service'
import GeofenceListItem from './GeofenceListItem/Geofence-List-Item.vue'
import GeofenceListForm from './GeofenceListForm/Geofence-List-Form.vue'
import FileUploadDialog from './FileUploadDialog/FileUploadDialog.vue'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { Geofence } from '@/store/interfaces/geofence.interfaces'

const geofenceStore = namespace('Geofence')
const sharedStore = namespace('Shared')

@Component({ components: { GeofenceListItem, GeofenceListForm, FileUploadDialog } })

export default class DevicesList extends Vue {
  $refs: {
    geofenceListForm: HTMLFormElement
  }

  @geofenceStore.State
  private geofences: Geofence[]

  @geofenceStore.Mutation
  private setGeofences: (geofences: Geofence[]) => void

  @geofenceStore.Mutation
  private updateGeofence: (geofence: Partial<Geofence>) => void

  @geofenceStore.Mutation
  private removeGeofence: (id: string) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  public isMinimized = false
  public isSelectedAllGeofences = false
  public isLoading = false
  public search = '';
  public isFormShow = false

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      this.setGeofences(await GeofenceService.fetch())
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }

  public toggle(geofence: Geofence): void {
    const geofenceItem = this.geofences.find(g => g._id === geofence._id)

    if (geofenceItem) {
      this.updateGeofence({ ...geofenceItem, isShowOnMap: !geofenceItem.isShowOnMap })

      if (!geofenceItem.isShowOnMap) {
        this.$emit('centeringMap', geofenceItem.coordinates[0])
      }
    }
  }

  public async remove(id: string): Promise<void> {
    const title = this.$t('geofencePage.geofenceList.deleteGeofenceConfirmTitle').toString()
    const text = `
        ${this.$t('geofencePage.geofenceList.deleteGeofenceConfirmText1')})<br/>
        ${this.$t('geofencePage.geofenceList.deleteGeofenceConfirmText2')})
    `

    if (await this.confirm({ title, text })) {
      try {
        await GeofenceService.destroy(id)
        this.removeGeofence(id)

        this.setNotification({ text: this.$t('geofencePage.geofenceList.geofenceDeletedNotificationText').toString() })
      } catch (e) {
        console.log(e)
      }
    }
  }

  public edit({ _id, name, type, radius, description, color, coordinates }: Geofence): void {
    this.isFormShow = true
    this.updateGeofence({ _id, isShowOnMap: false })
    this.$emit('centeringMap', coordinates[0])
    setTimeout(() => this.$refs.geofenceListForm.edit(_id, { name, type, radius, coordinates, description, color }), 50)
  }

  public toggleAllGeofences(): void {
    this.isSelectedAllGeofences = !this.isSelectedAllGeofences

    this.geofences.forEach(g => this.updateGeofence({ ...g, isShowOnMap: this.isSelectedAllGeofences }))

    if (this.isSelectedAllGeofences) {
      this.$emit('centeringMap', this.geofences.map(g => g.coordinates[0]))
    }
  }
}
