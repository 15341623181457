import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component
export default class GeofenceListItem extends Vue {
  @Prop({ required: true })
  public readonly geofenceName: string

  @Prop({ default: false })
  public readonly isActive: boolean

  @sharedStore.State
  private readonly userInfo: UserInfo

  public menuItems: MenuItem[] = [
    { text: this.$t('geofencePage.geofenceList.geofenceListItem.showOnMapText').toString(), icon: 'fa fa-map-marker-alt', color: '949494', action: () => this.$emit('show') }
  ]

  public created(): void {
    if (this.userInfo.role !== 'operator') {
      this.menuItems.push(
        { text: this.$t('geofencePage.geofenceList.geofenceListItem.editText').toString(), icon: 'fa fa-pen', color: '949494', action: () => this.$emit('edit') },
        { text: this.$t('geofencePage.geofenceList.geofenceListItem.deleteText').toString(), icon: 'fa fa-trash', color: 'red', action: () => this.$emit('remove') }
      )
    }
  }
}

interface MenuItem {
  text: string;
  icon: string;
  color: string;
  action: () => void;
}
