import { Component, Vue } from 'vue-property-decorator'
import FileUpload from 'vue-upload-component'

@Component({
  components: { FileUpload }
})
export default class FileUploader extends Vue {
  public files: [] = [];
  public errorMessage = '';
  public fileName = '';

  public inputFilter(newFile: any, oldFile: any, prevent: any): any {
    if (!/\.(xlsx)$/i.test(newFile.name)) {
      this.errorMessage = this.$t('geofencePage.geofenceList.fileUploadDialog.fileUploader.errorMessage').toString()
      return prevent()
    }

    this.errorMessage = ''
    this.fileName = newFile.name

    const reader = new FileReader()
    reader.readAsDataURL(newFile.file)
    reader.onload = () => {
      this.$emit('fileUploaded', reader.result!.toString().replace(/^data:(.*,)?/, ''))
    }

    return null
  }

  public reset(): void {
    this.files = []

    this.$emit('fileUploaded', null)
  }
}
