import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { GeofenceService } from '@/App/Services/Geofence.service'
import ColorPicker from '@/App/Components/ColorPicker/Color-Picker.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { GeofenceItem, GeofenceType, StoreGeofence } from '@/App/Services/interfaces/geofence.interface'
import { VuetifySelect } from '@/interfaces/vuetify'

const sharedStore = namespace('Shared')
const geofenceStore = namespace('Geofence')

@Component({
  components: { ColorPicker }
})
export default class GeofenceListForm extends Mixins(Validator) {
  $refs!: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @geofenceStore.State
  private geofenceForm: StoreGeofence

  @geofenceStore.Mutation
  private addGeofence: (geofence: GeofenceItem) => void

  @geofenceStore.Mutation
  private updateGeofence: (geofence: GeofenceItem) => void

  @geofenceStore.Mutation
  private togglePointAdding: () => void

  @geofenceStore.Mutation
  private updateGeofenceForm: (geofence: Partial<StoreGeofence>) => void

  public isLoading = false
  public form = false
  public geofenceId: string | null = null;
  public geofence: GeofenceForm = {
    name: '',
    type: 'circle',
    radius: 20,
    description: '',
    color: '#3e65fe'
  }
  public geofenceTypeItems: VuetifySelect[] = [
    { text: this.$t('geofencePage.geofenceList.geofenceListForm.circleText').toString(), value: 'circle' },
    { text: this.$t('geofencePage.geofenceList.geofenceListForm.polygonText').toString(), value: 'polygon' }
  ]
  public latitude: number | null = null
  public longitude: number | null = null

  public mounted(): void {
    this.togglePointAdding()
  }

  public destroyed(): void {
    this.togglePointAdding()
    this.updateGeofenceForm({
      name: '',
      type: 'circle',
      radius: 20,
      coordinates: [],
      description: '',
      color: '3e65fe'
    })
  }

  public edit(geofenceId: string, val: GeofenceItem): void {
    this.geofenceId = geofenceId
    this.geofence = { ...val }
  }

  public async save(): Promise<void> {
    try {
      this.isLoading = true

      this.addGeofence(await GeofenceService.store(this.geofenceForm))

      this.setNotification({ text: this.$t('geofencePage.geofenceList.geofenceListForm.geofenceCreatedNotificationText').toString() })
      this.$emit('formCloseHandler')
    } catch (e) {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      if (this.geofenceId) {
        this.isLoading = true
        this.updateGeofence(await GeofenceService.update(this.geofenceId, this.geofenceForm))

        this.setNotification({ text: this.$t('geofencePage.geofenceList.geofenceListForm.geofenceUpdatedNotificationText').toString() })
        this.$emit('formCloseHandler')
      }
    } catch (e) {} finally {
      this.isLoading = false
    }
  }

  public coordinatesChanged(val: number, position: 0 | 1): void {
    console.log(val, position)
  }

  public clearPolygonPoints(): void {
    this.updateGeofenceForm({ coordinates: [] })
  }

  public latitudeChanged(): void {
    this.updateGeofenceForm({ coordinates: [[this.latitude || 0, this.geofenceForm.coordinates[0] ? this.geofenceForm.coordinates[0][1] : 0]] })
  }

  public longitudeChanged(): void {
    this.updateGeofenceForm({ coordinates: [[this.geofenceForm.coordinates[0] ? this.geofenceForm.coordinates[0][0] : 0, this.longitude || 0]] })
  }

  @Watch('geofence', { deep: true })
  private geofenceFormChanged(): void {
    this.updateGeofenceForm(this.geofence)
  }

  @Watch('geofenceForm')
  private geofenceFormStateChanged(val: StoreGeofence): void {
    this.latitude = val.coordinates[0][0] || null
    this.longitude = val.coordinates[0][1] || null
  }

  get isBtnDisabled(): boolean {
    if (this.geofence.type === 'circle') {
      return !this.form || !this.geofenceForm.coordinates.length
    }

    return !this.form || this.geofenceForm.coordinates.length < 3
  }
}

interface GeofenceForm {
  name: string
  type: GeofenceType
  radius: number
  description: string
  color: string
}
